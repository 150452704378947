'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var createNotification = require('eminence/components/notification');
var urlHelpers = require('eminence/utils/urlHelpers');
const { CLASSES } = require('eminence/utils/globals');

const SELECTORS = {
    window: window,
    countrySelector: '.js-country-selector',
    notificationMessage: '.js-notification-message, .error-messaging',
    countryChangedMessage: '.js-country-changed',
    isCountrySelectDisabled: '.js-is-country-select-disabled',
    switchLocale: '.js-switch-locale'
};

module.exports = function () {
    $(SELECTORS.countrySelector).on('click', function (e) {
        e.preventDefault();
        var action = $('.page').data('action');
        var localeCode = $(this).data('locale');
        var localeCurrencyCode = $(this).data('currencycode');
        var queryString = $('.page').data('querystring');
        var url = $(SELECTORS.countrySelector).data('url');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
                code: localeCode,
                queryString: queryString,
                CurrencyCode: localeCurrencyCode,
                action: action
            },
            success: function (response) {
                $.spinner().stop();
                if (response && response.redirectUrl) {
                    let redirectUrl = response.redirectUrl;
                    if (action == 'Cart-Show') {
                        let href = urlHelpers.appendParamToURL(redirectUrl, 'localeChanged', true);
                        window.location.href = href;
                        return;
                    }
                    window.location.href = redirectUrl;
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $(window).on('load', function() {
        let href = window.location.href;
        const localeChanged = href.includes('localeChanged=true');
        if (localeChanged) {
            href = urlHelpers.removeParamFromURL(href, 'localeChanged');
            window.history.replaceState(null, '', href);

            let $countryChangedMessage = $(SELECTORS.countryChangedMessage);
            if ($countryChangedMessage && $countryChangedMessage.length > 0) {
                createNotification($(SELECTORS.notificationMessage), $countryChangedMessage.html(), 'info', 15000);
            }
        }
    });

    $(document).ready(function () {
        var isCountrySelectDisabled = $(SELECTORS.isCountrySelectDisabled).length && $(SELECTORS.isCountrySelectDisabled).val() == 'true';
        if (isCountrySelectDisabled) {
            let $countrySelector = $(SELECTORS.countrySelector);
            $countrySelector.addClass('h-hide');

            var switchLocale = $(SELECTORS.switchLocale).length && $(SELECTORS.switchLocale).val() == 'true';
            if (switchLocale) { // Redirect to correct locale
                $countrySelector.trigger('click');
            }
        }
    });

    keyboardAccessibility(
        '.navbar-header .country-selector',
        {
            40: function ($countryOptions) { // down
                if ($(this).is(':focus')) {
                    $countryOptions.first().focus();
                } else {
                    $(':focus').next().focus();
                }
            },
            38: function ($countryOptions) { // up
                if ($countryOptions.first().is(':focus') || $(this).is(':focus')) {
                    $(this).focus();
                    $(this).removeClass(CLASSES.show);
                } else {
                    $(':focus').prev().focus();
                }
            },
            27: function () { // escape
                $(this).focus();
                $(this).removeClass(CLASSES.show).children('.dropdown-menu').removeClass(CLASSES.show);
            },
            9: function () { // tab
                $(this).removeClass(CLASSES.show).children('.dropdown-menu').removeClass(CLASSES.show);
            }
        },
        function () {
            if (!($(this).hasClass(CLASSES.show))) {
                $(this).addClass(CLASSES.show);
            }
            return $(this).find('.dropdown-country-selector').children('a');
        }
    );

    $('.navbar-header .country-selector').on('focusin', function () {
        $(this).addClass(CLASSES.show).children('.dropdown-menu').addClass(CLASSES.show);
    });
};
