'use strict';

window.jQuery = require('jquery');
window.$ = require('jquery');
var processInclude = require('base/util');
const { CLASSES } = require('./utils/globals');

const SELECTORS = {
    stickyHeader: '.header-container',
}

$(document).ready(function () {
    processInclude(require('base/components/menu'));
    processInclude(require('eminence/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('eminence/components/miniCart'));
    processInclude(require('eminence/login'));
    processInclude(require('eminence/components/collapsibleItem'));
    processInclude(require('eminence/components/search'));
    processInclude(require('eminence/components/clientSideValidation'));
    processInclude(require('eminence/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('eminence/components/select2'));
    processInclude(require('eminence/components/slickSlider'));
    processInclude(require('eminence/components/newsletter'));
    processInclude(require('eminence/components/globalNotifications'));

    processInclude(require('eminence/debug')); // remove later
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');

$(window).scroll(function () {
    if ($(window).scrollTop() >= 80) {
        $(SELECTORS.stickyHeader).addClass(CLASSES.sticky);
    } else {
        $(SELECTORS.stickyHeader).removeClass(CLASSES.sticky);
    }
 });

$(function(){
    if ($('#country-prompt').length > 0) {
        $('.countryCta').trigger('click');
    }
});
